import Head from "next/head";
import Image from "next/image";
import Glider from "react-glider";

import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { ImageFit } from "database";

import { FeaturedCollections } from "@components/featured/FeaturedCollections";
import { FeaturedTopCollections } from "@components/featured/FeaturedTopCollections";
import { Footer } from "@components/Footer";
import { MarketHeader } from "@components/MarketHeader";
import { Skeleton } from "@components/Skeleton";
import { Subscribe } from "@components/Subscribe";
import { MarketplaceLink } from "@components/MarketplaceLink";

import { simplifyAddress } from "@utils/index";
import { trpc } from "@utils/trpc";

import gradientDark from "@public/img/gradient_dark.jpg";
import TestImage from "@public/img/products/item_1.jpg";
import PlaceholderImage from "@public/img/products/item_13_lg.gif";
import gradient from "@public/img/gradient.jpg";
import gradientLight from "@public/img/gradient_light.jpg";

type FormValues = {
  text: string;
};

const MainPage = () => {
  const { data, isLoading } = trpc.tokens.getAll.useQuery({
    take: 9,
    where: {
      featured: true,
    },
  });

  const numberSkeletonArts = [0, 1, 2, 3, 4, 5];
  const { data: collectionData } = trpc.collections.countFeatured.useQuery({
    take: 4,
  });

  const { data: topCollections } = trpc.collections.getAllExecuted.useQuery({
    take: 4,
  });

  const { register, handleSubmit } = useForm<FormValues>();

  const { push } = useRouter();

  return (
    <>
      <MarketHeader />
      <Head>
        <title>{"Spaceship Marketplace"}</title>
      </Head>
      <main className="overflow-x-hidden font-body text-jacarta-500 dark:bg-jacarta-900">
        <section className="hero relative py-20 md:pt-32">
          <div className="pointer-events-none absolute inset-0 top-0 -z-10 dark:hidden">
            <Image placeholder="blur" alt="" src={gradient} fill />
          </div>
          <div className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
            <Image placeholder="blur" alt="" src={gradientDark} fill />
          </div>

          <div className="container">
            <div className="mx-auto max-w-2xl pt-24 text-center">
              <h1 className="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
                Discover, Collect {"&"} Sell
                <span className="animate-gradient" style={{ display: "block" }}>
                  Creative NFTs
                </span>
              </h1>
              <form
                className="relative mb-4"
                onSubmit={handleSubmit((values) => {
                  push({
                    pathname: "/marketplace/search",
                    query: {
                      query: values.text,
                    },
                  });
                })}
              >
                <input
                  type="search"
                  className="w-full rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-md text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                  placeholder="Search by Collection, NFT or user"
                  {...register("text")}
                />
                <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-4 w-4 fill-jacarta-500 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                  </svg>
                </span>
              </form>

              {/* <span className="dark:text-jacarta-300">Popular searches:</span>
            <a
              href="#"
              className="text-accent hover:text-jacarta-700 dark:hover:text-white"
            >
              cryptopunks
            </a>
            <span>,</span>
            <a
              href="#"
              className="text-accent hover:text-jacarta-700 dark:hover:text-white"
            >
              bored ape yacht club
            </a>
            <span>,</span>
            <a
              href="#"
              className="text-accent hover:text-jacarta-700 dark:hover:text-white"
            >
              moonbirds
            </a> */}
            </div>
          </div>
        </section>

        <div className=" relative px-6 pb-16 sm:px-0">
          {!isLoading && data?.arts && data?.arts.length > 0 ? (
            <>
              <Glider
                draggable
                hasDots
                hasArrows
                className="!py-5"
                itemWidth={190}
                slidesToShow={"auto"}
                arrows={{
                  next: "#rightArrow",
                  prev: "#leftArrow",
                }}
                responsive={[
                  {
                    breakpoint: 310,
                    settings: {
                      slidesToShow: 1.5,
                    },
                  },
                  {
                    breakpoint: 414,
                    settings: {
                      itemWidth: 400,
                      slidesToShow: "auto",
                    },
                  },
                ]}
              >
                {data?.arts &&
                  data?.arts.map((art) => {
                    return (
                      <article key={`${art.collectionAddress}-${art.id}`}>
                        <div className="mx-auto block w-40 overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700 xs:w-[375px]">
                          <figure className="relative">
                            <MarketplaceLink
                              href={`/${art.collectionAddress}/${art.id}`}
                            >
                              <div className="relative h-44 w-40 xs:h-[430px] xs:w-[375px]">
                                <Image
                                  src={art.imageUrl ?? PlaceholderImage}
                                  className="swiper-lazy object-cover object-center"
                                  quality={100}
                                  priority
                                  fill
                                  alt="Art image"
                                />
                              </div>
                            </MarketplaceLink>
                          </figure>
                          <div className="p-6">
                            <div className="flex">
                              <MarketplaceLink
                                href={`/${art.collectionAddress}`}
                                className="hidden shrink-0 xs:block"
                              >
                                <div className="relative mr-4 h-10 w-10 overflow-hidden rounded-full">
                                  <Image
                                    src={
                                      art.collection?.imageUrl
                                        ? art.collection?.imageUrl
                                        : TestImage
                                    }
                                    alt="Collection Avatar"
                                    fill
                                  />
                                </div>
                              </MarketplaceLink>
                              <div>
                                <MarketplaceLink
                                  href={`/${art.collectionAddress}/${art.id}`}
                                  className="block"
                                >
                                  <span className="font-display text-sm leading-none text-jacarta-700 hover:text-accent dark:text-white sm:text-md md:text-lg">
                                    {art.title}
                                  </span>
                                </MarketplaceLink>
                                <MarketplaceLink
                                  href={`/${art.collectionAddress}`}
                                  className="text-xs text-accent md:text-2xs"
                                >
                                  <div className="max-w-[15ch] truncate">
                                    {art.collection?.name ??
                                      art.collectionAddress}
                                  </div>
                                </MarketplaceLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    );
                  })}
              </Glider>
              <div
                id="leftArrow"
                className="group absolute top-1/2 left-4 z-10 -mt-6 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white p-1 text-base drop-shadow-md md:h-12 md:w-12 md:p-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 group-hover:fill-accent"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
              </div>
              <div
                id="rightArrow"
                className="group absolute top-1/2 right-4 z-10 -mt-6 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white p-1 text-base drop-shadow-md md:h-12 md:w-12 md:p-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 group-hover:fill-accent"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </div>
            </>
          ) : (
            <>
              <div className="flex space-x-10 px-6 !py-5">
                {numberSkeletonArts.map((_, i) => {
                  return (
                    <article key={i} className="block w-[375px]">
                      <div className="mx-auto block w-[375px] overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                        <figure className="relative h-[430px]">
                          <Skeleton />
                        </figure>
                        <div className="m-6 flex items-center space-x-4">
                          <div className="h-11 w-11 overflow-hidden rounded-full">
                            <Skeleton />
                          </div>
                          <div className="mt-2 flex h-3/4 grow flex-col">
                            <Skeleton className="grow" />
                            <Skeleton height={12} />
                          </div>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            </>
          )}
          {isLoading && <div className="h-80 w-full" />}
        </div>

        <section className="relative py-24 dark:bg-jacarta-800">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <Image src={gradientLight} alt="" className="h-full w-full" />
          </picture>
          <FeaturedTopCollections collections={topCollections} />
        </section>

        <section className="relative py-24 dark:bg-jacarta-800">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <Image src={gradientLight} alt="" className="h-full w-full" />
          </picture>
          <div className="container">
            <h2 className="mb-16 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              Create and sell your NFTs
            </h2>
            <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
              <div className="text-center">
                <div className="mb-6 inline-flex rounded-full bg-[#f2d3c4] p-3">
                  <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-accent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-5 w-5 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                  1. Set up your wallet
                </h3>
                <p className="dark:text-jacarta-300">
                  Once you&apos;ve set up your wallet of choice, connect it to
                  OpenSea by clicking the NFT Marketplace in the top right
                  corner.
                </p>
              </div>
              <div className="text-center">
                <div className="mb-6 inline-flex rounded-full bg-[#C4F2E3] p-3">
                  <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-5 w-5 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                  2. Create Your Collection
                </h3>
                <p className="dark:text-jacarta-300">
                  Click Create and set up your collection. Add social links, a
                  description, profile & banner images, and set a secondary
                  sales fee.
                </p>
              </div>
              <div className="text-center">
                <div className="mb-6 inline-flex rounded-full bg-[#CDDFFB] p-3">
                  <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-5 w-5 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M17.409 19c-.776-2.399-2.277-3.885-4.266-5.602A10.954 10.954 0 0 1 20 11V3h1.008c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3H6V1h2v4H4v7c5.22 0 9.662 2.462 11.313 7h2.096zM18 1v4h-8V3h6V1h2zm-1.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                  3. Add Your NFTs
                </h3>
                <p className="dark:text-jacarta-300">
                  Upload your work (image, video, audio, or 3D art), add a title
                  and description, and customize your NFTs with properties,
                  stats.
                </p>
              </div>
              <div className="text-center">
                <div className="mb-6 inline-flex rounded-full bg-[#FFD0D0] p-3">
                  <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-5 w-5 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z" />
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                  4. List Them For Sale
                </h3>
                <p className="dark:text-jacarta-300">
                  Choose between auctions, fixed-price listings, and
                  declining-price listings. You choose how you want to sell your
                  NFTs!
                </p>
              </div>
            </div>

            <p className="mx-auto mt-20 max-w-2xl text-center text-lg text-jacarta-700 dark:text-white">
              Join our mailing list to stay in the loop with our newest feature
              releases, NFT drops, and tips and tricks for navigating Spaceship
            </p>

            <div className="mx-auto mt-7 max-w-md text-center">
              <Subscribe />
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container">
            <h2 className="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              <span
                className="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
                style={{
                  backgroundImage:
                    "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f4a5.png)",
                }}
              ></span>
              Featured collections
            </h2>
            {!isLoading ? (
              collectionData && collectionData.length > 0 ? (
                <FeaturedCollections
                  collections={
                    collectionData?.map(
                      ({
                        name,
                        minterAddress,
                        minter,
                        address,
                        tokens,
                        _count,
                        verified,
                      }) => ({
                        name,
                        verified,
                        author: {
                          name: minter.name ?? simplifyAddress(minterAddress),
                          image: minter.imageUrl || PlaceholderImage.src,
                          address: minterAddress,
                        },
                        amount: `${_count.tokens}`,
                        collectionAddress: address,
                        artImages: {
                          main: {
                            url: `${tokens[0]?.imageUrl || ""}`,
                            imageFit: tokens[0]?.cardFit || ImageFit.COVER,
                          },
                          secondary: tokens
                            .slice(1)
                            .map(({ imageUrl, cardFit }) => ({
                              url: imageUrl,
                              imageFit: cardFit || ImageFit.COVER,
                            })),
                        },
                      })
                    ) || []
                  }
                />
              ) : (
                <div>
                  <h3 className="text-center text-lg">
                    No Featured Collections
                  </h3>
                </div>
              )
            ) : (
              <div className="flex max-w-full overflow-x-hidden !py-5">
                {numberSkeletonArts.map((_, i) => {
                  return (
                    <article key={i}>
                      <div className="mx-3 w-min rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                        <div className="flex space-x-[0.625rem]">
                          <div className="h-[242px] w-[152px] rounded-[0.625rem]">
                            <Skeleton className="h-[242px] w-[152px] rounded-[0.625rem]"></Skeleton>
                          </div>
                          <span className="flex w-[68px] flex-col gap-2.5 space-y-[0.625rem]">
                            <div className="space-y-1 rounded-[0.625rem]">
                              <div>
                                <Skeleton
                                  className="rounded-[0.625rem]"
                                  height={74}
                                  width={68}
                                />
                              </div>
                              <div>
                                <Skeleton
                                  className="rounded-[0.625rem]"
                                  height={74}
                                  width={68}
                                />
                              </div>
                              <div>
                                <Skeleton
                                  className="rounded-[0.625rem]"
                                  height={74}
                                  width={68}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
            )}
          </div>
        </section>

        {/* <div className="bg-light-base dark:bg-jacarta-800">
          <div className="container">
            <div className="grid grid-cols-2 py-8 sm:grid-cols-5">
              <a href="#">
                <Image src={partnerLogo1} alt="partner 1" />{" "}
              </a>
              <a href="#">
                <Image src={partnerLogo2} alt="partner 2" />{" "}
              </a>
              <a href="#">
                <Image src={partnerLogo3} alt="partner 3" />{" "}
              </a>
              <a href="#">
                <Image src={partnerLogo4} alt="partner 4" />{" "}
              </a>
              <a href="#">
                <Image src={partnerLogo5} alt="partner 5" />{" "}
              </a>
            </div>
          </div>
        </div> */}
      </main>
      <Footer />
    </>
  );
};

export default MainPage;
