import { Collection } from "database";

import { defaultUserImage } from "@utils/index";
import { MiniCard } from "@components/cards/MiniCard";

interface FeaturedTopCollectionsProps {
  collections?: Omit<Collection, "collectionType" | "domain" | "removedAt">[];
}

export const FeaturedTopCollections = ({
  collections,
}: FeaturedTopCollectionsProps) => {
  return (
    <div className="container">
      <div className="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
        <h2 className="mr-[0.75ch] inline">Top collections over</h2>
        <div className="inline">
          <span className="inline-flex items-center text-accent">
            last 30 days
          </span>
        </div>
      </div>

      <div className="grid grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-3 md:gap-[1.875rem]">
        {collections?.map(
          (
            { imageUrl, description, name, verified, minterAddress, address },
            index
          ) => {
            return (
              <MiniCard
                image={{
                  src: imageUrl || defaultUserImage({ address }, "identicon"),
                  link: `/${address}`,
                }}
                description={description}
                title={name}
                position={index + 1}
                verified={verified}
                user={minterAddress}
                key={index}
              />
            );
          }
        )}
      </div>
      <div className="mt-10 text-center">
        <a
          href="rankings.html"
          className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
        >
          Go to Rankings
        </a>
      </div>
    </div>
  );
};
