import Image from "next/image";

import { MarketplaceLink } from "@components/MarketplaceLink";

import { simplifyAddress } from "@utils/index";

interface MiniCardProps {
  title: string;
  description: string;
  image: {
    src: string;
    link: string;
    alt?: string;
  };
  user: string;
  position?: number;
  verified: boolean | null;
}

const IMAGE_SIZE = 48;

export const MiniCard = ({
  title,
  image,
  position,
  verified,
  user,
}: MiniCardProps) => {
  return (
    <div className="flex h-min rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700">
      <figure className="relative mr-4 h-[50px]">
        <div
          style={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
          }}
          className="relative"
        >
          <Image
            src={image.src}
            alt={image.alt ?? ""}
            className="h-min rounded-2lg object-cover"
            fill
            loading="lazy"
          />
        </div>
        {position && (
          <div className="absolute -left-3 top-[47%] z-10 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white bg-jacarta-700 text-xs text-white dark:border-jacarta-600">
            {position}
          </div>
        )}
        {verified && (
          <div
            className="absolute -right-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green-500 dark:border-jacarta-600"
            data-tippy-content="Verified Collection"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              className="h-[.875rem] w-[.875rem] fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
            </svg>
          </div>
        )}
      </figure>

      <div>
        <MarketplaceLink
          href={image.link}
          className="text-jacarta-70 font-display font-semibold hover:text-accent dark:text-white dark:hover:text-accent"
        >
          {title}
        </MarketplaceLink>

        <MarketplaceLink
          href={`/user/${user}`}
          className="block truncate text-sm hover:text-accent dark:text-jacarta-300 dark:hover:text-accent"
        >
          by {simplifyAddress(user)}
        </MarketplaceLink>
      </div>
    </div>
  );
};
